<template>
  <div class="classData">
    <van-tabs v-model:active="activeClass" @change="changeClass">
      <van-tab
        v-for="item in userInfo.bindData.classSubject"
        :title="item.className"
      ></van-tab>
    </van-tabs>
    <CaseOverview :dataOverview="dataOverview"></CaseOverview>
    <!-- <div class="charts"><CaseData></CaseData></div> -->
    <div class="charts2">
      <CaseDistr :title="'平均正确率人数分布'" :data="correctRate"></CaseDistr>
    </div>
    <div class="charts2">
      <CaseDistr :title="'平均用时人数分布'" :data="avgTakeTime"></CaseDistr>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import CaseOverview from "../components/CaseOverview.vue";
import CaseData from "@/views/chart/CaseData.vue";
import CaseDistr from "@/views/chart/CaseDistr.vue";
import { strToBase64 } from "@/common/js/utils.js";
import { apiGetOverviewOfAll, apiDistribution } from "@/service/teacher1.4.js";

const route = useRoute();
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

onBeforeMount(async () => {
  getPieData();
  getAvgTakeTime();
  getOverViewData();
});

let dataOverview = ref([]);
let activeClass = ref(0);

const getOverViewData = async (start_time, end_time) => {
  let start = new Date(new Date(new Date().toLocaleDateString()).getTime());
  let end = new Date(
    new Date(new Date().toLocaleDateString()).getTime() +
      24 * 60 * 60 * 1000 -
      1
  );

  let res = await apiGetOverviewOfAll({
    start_time: start_time ? start_time : start.getTime() / 1000,
    end_time: end_time ? end_time : Math.floor(end.getTime() / 1000),
    class_id: Array.isArray(userInfo.bindData)
      ? ""
      : userInfo.bindData.classSubject[activeClass.value].class_id,
  });
  if (res) {
    dataOverview.value.push(
      {
        num: res.data.reviewstrNum,
        pNum: res.data.caseNum,
        title: "评语/学案数",
      },
      { num: res.data.qaNum, pNum: res.data.scoureNum, title: "题目/资料数" },
      { num: res.data.issueNum, title: "题目疑问数" },
      {
        num: res.data.submitPNum,
        pNum: res.data.pendingSubmitPNum,
        title: "提交/待提交人数",
      },
      {
        num: res.data.reviewPNum,
        pNum: res.data.pendingReviewPNum,
        title: "批阅/待批阅人数",
      },
      {
        num: res.data.reviewed,
        pNum: res.data.pendingReview,
        title: "批阅/待批阅次数",
      },
      {
        num: res.data.submitedNum,
        pNum: res.data.pendingSubmitNum,
        title: "批阅/待批阅次数",
      },
      {
        num: res.data.avgPTakeTime,
        title: "人均用时（分钟）",
      },
      {
        num: res.data.sumTakeTime,
        title: "总用时（分钟）",
      }
    );
  }
};

let classId = ref(0);
const changeClass = (e) => {};
// 获取平均正确率和平均用时饼图数据
let correctRate = ref([]);
const getPieData = async () => {
  let params = strToBase64(
    JSON.stringify([
      "correctRate >= 50,小于等于50",
      "correctRate > 50 && correctRate <= 60,大于50小于等于60",
      "correctRate > 60 && correctRate <= 80,大于60小于等于80",
      "correctRate > 80 && correctRate <= 90,大于80小于等于90",
      "correctRate > 90,大于90",
    ])
  );

  let res = await apiDistribution({
    selects: params,
  });
  if (res) {
    correctRate.value = res.data;
  }
};
// 获取平均用时饼图
let avgTakeTime = ref([]);
const getAvgTakeTime = async () => {
  let params = strToBase64(
    JSON.stringify([
      "avgTakeTime  >= 10,小于等于10min",
      "avgTakeTime  > 10 && avgTakeTime  <= 30,大于10小于等于30min",
      "avgTakeTime  > 30 && avgTakeTime  <= 60,大于30小于等于60min",
      "avgTakeTime  > 90 && avgTakeTime  <= 120,大于90小于等于120min",
      "avgTakeTime  > 120,大于120min",
    ])
  );

  let res = await apiDistribution({
    selects: params,
    class_id: Array.isArray(userInfo.bindData)
      ? ""
      : userInfo.bindData.classSubject[activeClass.value].class_id,
  });
  if (res) {
    avgTakeTime.value = res.data;
  }
};

// 监听路由
watch(
  () => route.query,
  async () => {
    // 日
    if (route.query.date == "0") {
      dataOverview.value = [];
      getOverViewData();
    }
    // 周
    if (route.query.date == "1") {
      let date = new Date();
      //本周第一天
      let startTime = new Date(
        new Date(date).getTime() -
          3600 *
            1000 *
            24 *
            (new Date(date).getDay() == 0 ? 6 : new Date(date).getDay() - 1)
      );
      //本周最后一天
      let endTime = new Date(
        new Date(date).getTime() +
          3600 *
            1000 *
            24 *
            (new Date(date).getDay() == 0 ? 0 : 7 - new Date(date).getDay(date))
      );
      dataOverview.value = [];
      await getOverViewData(
        Math.floor(startTime.getTime() / 1000),
        Math.floor(endTime.getTime() / 1000)
      );
    }
    // 月
    if (route.query.date == "2") {
      dataOverview.value = [];
      await getOverViewData(
        new Date(getFirstDay()).getTime() / 1000,
        new Date(getLastDay()).getTime() / 1000
      );
    }
    // 年
    if (route.query.date == "3") {
      dataOverview.value = [];
      await getOverViewData(
        new Date(new Date().getFullYear() + " 01-01").getTime() / 1000,
        new Date(new Date().getFullYear() + " 12-31").getTime() / 1000
      );
    }
  }
);

// 获取本月第一天
function getFirstDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = "01";
  m = m < 10 ? "0" + m : m; //月份补 0
  return [y, m, d].join("-");
}
// 获取本月最后一天
function getLastDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = new Date(y, m, 0).getDate(); //获取当月最后一日
  m = m < 10 ? "0" + m : m; //月份补 0
  d = d < 10 ? "0" + d : d; //日数补 0
  return [y, m, d].join("-");
}
</script>

<style lang="less" scoped>
.charts {
  height: 600px;
}
.charts2 {
  height: 400px;
  margin-top: 10px;
}

:deep(.van-tabs) {
  margin-bottom: 10px;
  border-radius: 6px;
  overflow: hidden;
}
</style>
